// 模块
import { defineStore } from 'pinia'
import { store } from '../main'
import { message, openUrl } from '@/utils'
import { ApiGetEnginesr, ApiUpdataEnginesr } from '@/api/sync'
import type { WatchStopHandle } from 'vue'
import { SearchEnginesr } from '@/utils/data'
import { useSyncStoreWithOut } from './sync'
import { useBasicStoreWithOut } from './basic'
import type { Enginesr } from '@/types/public'


let enginesrWatch: WatchStopHandle
export const useDataStore = defineStore('enginesr', {
  // 开启持久化
  persist: {
    paths: ['enginesrAll', 'enginesrIndex']
  },
  state: () => ({
    enginesrAll: [
      { def: true, name: '百度' },
      { def: true, name: 'Google' },
      { def: true, name: '必应' },
      { def: true, name: '搜狗' }
    ] as Enginesr[],
    enginesrIndex: 0
  }),
  getters: {
    // 获取搜索引擎列表
    enginesr(state): Enginesr[] {
      const list: Enginesr[] = state.enginesrAll
      return list.map((x) => {
        return x.def
          ? {
              ...x,
              ...SearchEnginesr.find((j) => j.name === x.name)
            }
          : { ...x }
      })
    },
    // 获取当前搜索引擎
    currentEnginesr(): Enginesr {
      return this.enginesr[this.enginesrIndex]
    }
  },
  actions: {
    initSync() {
      const sync = useSyncStoreWithOut()
      ApiGetEnginesr()
        .then((res) => {
          if (res.type === 'ok' && res.json && sync.enginesr) {
            if(JSON.stringify(this.enginesrAll) !== res.json){
              this.$patch({ enginesrAll: JSON.parse(res.json) })
            }
          }
          // 云端没有数据,推送更新
          if (res.type === 'empty' && sync.enginesr) {
            this.upData()
          }
          this.startWatchEnginesr()
        })
        .catch(() => {
          message.warning('获取云端搜索引擎失败，刷新页面重试')
        })
      return this
    },
    stopWatchEnginesr() {
      enginesrWatch && enginesrWatch()
    },
    startWatchEnginesr() {
      const sync = useSyncStoreWithOut()
      this.stopWatchEnginesr()
      enginesrWatch = watch(
        [() => sync.enginesr, this.enginesrAll],
        ([sync, list]) => {
          if (!sync) return
          this.upData()
        },
        { deep: true }
      )
    },
    upData() {
      ApiUpdataEnginesr({ json: JSON.stringify(this.enginesrAll) }).catch(() => {
        message.warning('搜索引擎同步失败')
      })
    },
    /**
     * 添加搜索引擎
     * @param data 参数
     */
    addEnginer(data: string | { icon: string; name: string; url: string }) {
      const lenTip = () => {
        if (this.enginesrAll.length >= 20) {
          message.info('已经添加20个搜索引擎了，不可以再添加了哦（吃太多会长胖哦🤡）')
          return true
        }
      }
      if (typeof data == 'string') {
        if (this.enginesrAll.some((item) => item.def && item.name === data)) {
          message.info('这个搜索引擎已经添加了')
          return
        }
        if (lenTip()) return
        this.enginesrAll.push({
          def: true,
          name: data
        })
        return true
      }
      // 是否重名
      if (this.enginesrAll.some((item) => !item.def && item.name === data.name)) {
        message.info('这个名称已经添加过了，换个名字呗')
        return
      }
      if (lenTip()) return
      this.enginesrIndex = 0
      this.enginesrAll.push({
        name: data.name,
        url: data.url,
        icon: data.icon
      })
      return true
    },
    /**
     * 删除搜索引擎
     * @param index 下标
     */
    deleteEnginesr(index: number) {
      if (!this.enginesrAll[index]) {
        message.error('下标不存在')
        return
      }
      this.enginesrIndex = 0
      this.enginesrAll.splice(index, 1)
    },
    selectEnginesr(index: number) {
      if (!this.enginesr[index]) {
        message.error('下标不存在')
        return
      }
      this.enginesrIndex = index
    },
    goSearch(text: string) {
      const basic = useBasicStoreWithOut()
      if (!this.currentEnginesr.url) {
        message.warning('该搜索引擎没有配置搜索引擎')
        return
      }
      let searchUrl = this.currentEnginesr.url + text
      if (this.currentEnginesr.url.includes('{text}')) {
        searchUrl = this.currentEnginesr.url.replace('{text}', text)
      }
      openUrl(searchUrl, basic.searchNewTab)
    }
  }
})
export function useEnginesrStoreWithOut() {
  return useDataStore(store)
}
