import { provide, defineAsyncComponent } from 'vue'
import { upMethods, upMethodsPrivate } from '@/utils'
import { useSiteStoreWithOut } from '@/stores/site'
import { useEnginesrStoreWithOut } from '@/stores/enginesr'
const RightMenu = defineAsyncComponent(() => import('@/components/RightMenu/index.vue'))
// 是否打开设置
const openSetup = ref(false)
// 是否在加载中
const loading = ref(false)
// 组件是否显示
const shows = ref<{ [key: string]: boolean }>({})
// 组件列表
const components = ref<any[]>([])
// 右键菜单的ref
const rightMenu = ref<InstanceType<typeof RightMenu>>()
const compRefs: { [key: string]: any } = {}

// ref回调
function compRefCall(id, res) {
  compRefs[id] = res
}
function getId() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
}
function loadSetupComponent(path) {
  loading.value = true
  return path()
    .then((res) => {
      const id = getId()
      shows.value[id] = false
      components.value.push({
        id: id,
        comp: markRaw(res.default)
      })
      return id
    })
    .finally(() => {
      loading.value = false
    })
}
function loadDialog(path) {
  return loadSetupComponent(path).then((id: string) => {
    shows.value[id] = true
    return compRefs[id]
  })
}

const methods = {
  /**
   * 打开数据集设置
   */
  openSiteSetup: () => loadDialog(() => import('./setup/SiteListSetup/index.vue')),
  /**
   * 打开选择壁纸弹窗
   */
  openSelectWallpaper: () => loadDialog(() => import('./setup/SetupWallpaper/MoreDialog.vue')),
  /**
   * 打开选择主题弹窗
   */
  openThemeDialog: () => loadDialog(() => import('./setup/SetupTheme/MoreDialog.vue')),
  /**
   * 打开添加站点弹窗
   * @param id 父级Id
   * @param edit 是否是编辑状态，编辑请传入当前Id
   */
  openAddSite: async (id: string, edit?: boolean) => {
    ;(await loadDialog(() => import('./setup/SiteListSetup/AddDialog.vue')))?.upData(id, edit)
  },
  /**
   * 打开右键设置
   * @param event 事件
   * @param id Id
   */
  showSiteRightEdit: (event: MouseEvent, id?: string) =>
    rightMenu.value?.showSiteRightEdit(event, id),
  /**
   * 打开选择搜索引擎的下拉框
   * @param event 事件
   */
  openSelectEnginesr: (event: MouseEvent) => rightMenu.value?.showSelectEnginesr(event),
  /**
   * 打开设置
   */
  openSetup: () => (openSetup.value = true),
  /**
   * 转到站点
   * @param id Id
   * @param isNewTab 是否在新标签页打开，不传则按照用户的设置
   */
  goSiteById: (id: string, isNewTab?: boolean | undefined) =>
    useSiteStoreWithOut().goSiteById(id, isNewTab),
  /**
   * 搜索
   * @param text 搜索的内容
   */
  goSearch: (text: string) => useEnginesrStoreWithOut().goSearch(text)
}
// 导出类型
export type MethodsType = typeof methods

// 私有的方法
const privateMethods = {
  openSelectIcon: () => loadDialog(() => import('./setup/SelectIcon/index.vue')),
  openSelectDataVersion: () => loadDialog(() => import('./setup/SelectDataVersion/index.vue')),
  /**
   * 打开文件夹
   * @param id Id
   */
  openSeeFolder: async (id: string) => {
    ;(await loadDialog(() => import('./dialog/SeeFolder.vue')))?.upData(id)
  }
}
// 导出类型
export type PrivateMethodsType = typeof privateMethods

export function init() {
  upMethods(methods)
  for (const key in methods) {
    provide(`methods:${key}`, methods[key])
  }
  // 更新私有方法
  upMethodsPrivate(privateMethods)

  return {
    compRefCall,
    shows,
    components,
    loading,
    openSetup,
    init,
    rightMenu
  }
}
