<template>
  <div style="width: 100%; height: 100%">
    <div class="bj">
      <div
        :style="{
          background: wallpaper.css + ' no-repeat 0 0 / 100% 100%',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          zIndex: 1,
          opacity: loading ? 0 : 1,
          transition: 'all 0.5s'
        }"
      ></div>
      <div
        :style="{
          backdropFilter: `blur(${wallpaper.blur}px)`,
          backgroundColor: `rgba(0, 0, 0, ${wallpaper.mask / 100})`,
          zIndex: 2
        }"
      ></div>
      <!-- <div v-show="loading" style="width: 100%;height: 100%;position: absolute;top: 0;left: 0;z-index: 3;display: flex;align-items: center;justify-content: center;">
        <n-spin />
      </div> -->
    </div>
    <component v-if="theme.info.type === 'lib'" :is="comp" class="ThemeBox" />
    <n-spin class="centerTip" v-if="loadingComp" :show="loadingComp" :delay="500">
      <div style="width: 150px"></div>
      <template #description>
        <div style="white-space: nowrap">主题加载中...</div>
      </template>
    </n-spin>

    <div class="setup-btn" @click="openSetup()" v-if="!comp">
      <svg
        t="1712455780462"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="4330"
        width="256"
        height="256"
      >
        <path
          d="M940.8 512C940.8 454.4 972.8 403.2 1024 384 998.4 294.4 947.2 211.2 883.2 147.2 838.4 179.2 774.4 185.6 723.2 160 672 128 646.4 76.8 652.8 19.2 608 6.4 556.8 0 512 0 460.8 0 416 6.4 371.2 19.2 377.6 70.4 352 128 300.8 160 249.6 192 185.6 185.6 140.8 147.2 76.8 211.2 25.6 294.4 0 384 51.2 403.2 83.2 454.4 83.2 512 83.2 569.6 51.2 620.8 0 640 25.6 729.6 76.8 812.8 140.8 876.8 185.6 844.8 249.6 838.4 300.8 864 352 896 377.6 947.2 371.2 1004.8 416 1017.6 467.2 1024 512 1024 563.2 1024 608 1017.6 652.8 1004.8 646.4 953.6 672 896 723.2 864 774.4 832 838.4 838.4 883.2 876.8 947.2 812.8 998.4 729.6 1024 640 972.8 620.8 940.8 569.6 940.8 512L940.8 512ZM512 723.2C390.4 723.2 294.4 627.2 294.4 512 294.4 396.8 390.4 300.8 512 300.8 633.6 300.8 729.6 396.8 729.6 512 729.6 627.2 633.6 723.2 512 723.2ZM512 640C582.692448 640 640 582.692448 640 512 640 441.307552 582.692448 384 512 384 441.307552 384 384 441.307552 384 512 384 582.692448 441.307552 640 512 640Z"
          fill="#aaa"
          p-id="4331"
        ></path>
      </svg>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useWallpaperStoreWithOut } from '@/stores/wallpaper'
import { useThemeStoreWithOut } from '@/stores/theme'
import { message, toFullUrlForBaseUrl } from '@/utils'
import axios from 'axios'
import { useMethods } from '@SDK/index';
const { openSetup } = useMethods()
const wallpaper = useWallpaperStoreWithOut()

const loading = ref(false)
watch(
  () => wallpaper,
  () => {
    // if(wallpaper.url.includes('http')){
    //   loading.value = true
    //   let img = new Image()
    //   img.src = wallpaper.url
    //   img.onload = () => {
    //     loading.value = false
    //   }
    // }
  },
  {
    deep: true
  }
)
const iframeSrc = ref('')
const comp = shallowRef()

const loadingComp = ref(false)
let cssLink
function loadCSS(href) {
  cssLink && cssLink.parentNode.removeChild(cssLink)
  cssLink = document.createElement('link')
  cssLink.rel = 'stylesheet'
  cssLink.type = 'text/css'
  cssLink.href = href
  document.getElementsByTagName('head')[0].appendChild(cssLink)
}

const loadModule = (url: string) => {
  loadCSS(toFullUrlForBaseUrl(url) + '/style.css')
  return axios
    .get(toFullUrlForBaseUrl(url) + '/theme-lib.iife.js')
    .then((res) => {
      return new Function(res.data)()
    })
    .catch((e) => {
      console.log(e)
      return Promise.reject('主题加载失败，请检查主题地址是否正确')
    })
}
const theme = useThemeStoreWithOut()
onMounted(() => {
  watch(
    () => theme.info,
    (info) => {
      if (info.type === 'lib') {
        comp.value = null
        loadingComp.value = true
        loadModule(info.isDev ? (info.devUrl || '') : info.url)
          .then((res: any) => {
            comp.value = res.component
            // theme.setup = res.options
          })
          .catch((msg) => {
            message.error(msg, {
              duration: 5000
            })
          })
          .finally(() => {
            loadingComp.value = false
          })
      } else if (info.type === 'iframe') {
        iframeSrc.value = info.url
      }
    },
    {
      immediate: true,
      deep: true
    }
  )
})
</script>
<style scoped lang="scss">
.ThemeBox {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  display: block;
  margin: 0;
}

.centerTip {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-text);
}
.setup-btn {
  position: absolute;
  right: 20px;
  top: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  padding: 2px;
  box-sizing: border-box;

  svg {
    width: 100%;
    height: 100%;
  }
}

.bj {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: 0.1s;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    transition: 0.3s;
  }
}
</style>
