import axios from 'axios'
import { message } from '.'
import { loginForWindow } from './auth'
import { setToken, setRefreshToken, getToken, getRefreshToken, removeToken } from './token'
import { ApiRefreshToken, isRefreshToken } from '@/api/auth'
import { onEvent } from './TJ'

const http = axios.create({
  // @ts-ignore
  baseURL: window?.config?.baseURL,
  timeout: 10000
})

http.interceptors.request.use((config) => {
  // @ts-ignore
  config.headers.SocketId = window._SocketId
  const token = isRefreshToken(config) ? getRefreshToken() : getToken()
  config.headers.Authorization = 'Bearer ' + token
  return config
})
http.interceptors.response.use(async (res) => {
  if (res.status !== 200) {
    return Promise.reject(res)
  }
  // 有新的roken
  if (res.headers.authorization) {
    const token = res.headers.authorization.replace('Bearer ', '')
    setToken(token)
  }
  if (res.headers.refreshtoken) {
    const token = res.headers.refreshtoken.replace('Bearer ', '')
    setRefreshToken(token)
  }
  if (res.data.code === 401 && !isRefreshToken(res.config)) {
    // 刷新token
    if (await ApiRefreshToken()) {
      return http.request(res.config) // 重新请求
    }
    removeToken()
    // 跳转登录页
    message.warning(res.data.msg || '登录失效，请重新登录')
    setTimeout(() => {
      loginForWindow()
    }, 1000)
    onEvent('登录过期', { 类型: 'Http认证', ...res.data })
    return Promise.reject(res.data)
  }

  if (res.data.code < 200 || res.data.code >= 300) {
    // 4000-4999 才提示错误
    if (res.data.code >= 4000 && res.data.code < 5000) {
      message.error(res.data.msg)
    }
    return Promise.reject(res.data)
  }
  return res.data.data
})

export default http
