export function getToken() {
  return localStorage.getItem('token')
}

export function setToken(token: string) {
  return localStorage.setItem('token', token)
}

export function removeToken() {
  localStorage.removeItem('refreshToken')
  return localStorage.removeItem('token')
}
export function getRefreshToken() {
  return localStorage.getItem('refreshToken')
}

export function setRefreshToken(token: string) {
  return localStorage.setItem('refreshToken', token)
}
