import type { Enginesr, SiteType, WallpaperType } from '@/types/public'
import type { MethodsType } from '@/views/index'
import { inject } from 'vue'
import SetupBtn from './component/SetupBtn.vue'
import SiteIcon from '@/components/SiteIcon.vue'
import { ThemeType } from '@/stores/theme'
export { SetupBtn, SiteIcon }

export function useMethods(): MethodsType {
  return new Proxy({}, { get: (_, prop) => inject('methods:' + String(prop)) }) as MethodsType
}

export type DataType = {
  theme: ThemeType,
  site: SiteType[]
  wallpaper: WallpaperType
  enginesr: Enginesr[] // 搜索引擎列表
  currentEnginesr: Enginesr // 当前使用的搜索引擎
}
export function useOptions(): DataType {
  return new Proxy({}, { get: (target, prop) => inject('data:' + String(prop)) }) as DataType
}
