import { MethodsType, PrivateMethodsType } from '@/views/index'
import { NIcon } from 'naive-ui'
import type { DialogApiInjection } from 'naive-ui/es/dialog/src/DialogProvider'
import type { MessageApiInjection } from 'naive-ui/es/message/src/MessageProvider'
import type { NotificationApiInjection } from 'naive-ui/es/notification/src/NotificationProvider'

type NaiveApiType = {
  message: MessageApiInjection
  dialog: DialogApiInjection
  notification: NotificationApiInjection
}
// 这个init需要在setup中执行一次
export function initUtils() {
  message = useMessage()
  notification = useNotification()
  dialog = useDialog()
}
export let { message, notification, dialog } = {} as NaiveApiType

export function renderIcon(icon: any) {
  return () => h(NIcon, null, { default: () => h(icon) })
}

export function toFullUrl(url?: string) {
  if (!url) {
    return ''
  }
  if (url.startsWith('http')) {
    return url
  }
  if (url.startsWith('/')) {
    // @ts-ignore
    return `${window?.config?.IconBaseUrl || ''}${url}`
  }
  return url
}

export function toFullUrlForBaseUrl(url?: string) {
  if (!url) {
    return ''
  }
  if (url.startsWith('http')) {
    return url
  }
  if (url.startsWith('/')) {
    // @ts-ignore
    return `${window?.config?.baseURL || ''}${url}`
  }
  return url
}

/**
 * 获取图片的base64
 * @param url
 * @returns
 */
function getBase64FromUrl(url) {
  return new Promise((resolve) => {
    const image = new Image()
    image.crossOrigin = 'anonymous'
    image.onload = function () {
      console.log(image)
      const canvas = document.createElement('canvas')
      const ctx: CanvasRenderingContext2D = canvas.getContext('2d') as any
      canvas.width = image.width
      canvas.height = image.height
      ctx.drawImage(image, 0, 0)
      resolve(canvas.toDataURL())
    }
    image.src = url
  })
}


// 一些公共的方法
let Methods = {} as MethodsType
export function upMethods(func: MethodsType) {
  Methods = func
}
export function useMethods(): MethodsType {
  return new Proxy({}, { get: (_, prop) => (...age) => Methods[String(prop)](...age)  }) as MethodsType
}

// 一些私有的方法
let MethodsPrivate = {} as PrivateMethodsType
export function upMethodsPrivate(func: PrivateMethodsType) {
  MethodsPrivate = func
}
export function useMethodsPrivate(): PrivateMethodsType {
  return new Proxy({}, { get: (_, prop) => (...age) => MethodsPrivate[String(prop)](...age)  }) as PrivateMethodsType
}

// 时间格式化
export function formatDate(time, formatStr = 'yyyy-MM-DD HH:mm:ss') {
  if (!time) {
    return '无'
  }
  const date = new Date(time)
  if (date.getTime() == 0) {
    return '无'
  }
  const paddingZero = (num) => (num >= 10 ? num : '0' + num)
  let str = formatStr
  str = str.replace(/yyyy|YYYY/, date.getFullYear() + '')
  str = str.replace(/MM/, paddingZero(date.getMonth() + 1))
  str = str.replace(/dd|DD/, paddingZero(date.getDate()))
  str = str.replace(/hh|HH/, paddingZero(date.getHours()))
  str = str.replace(/mm/, paddingZero(date.getMinutes()))
  str = str.replace(/ss/, paddingZero(date.getSeconds()))
  str = str.replace(/SS/, paddingZero(date.getMilliseconds()))
  return str
}

// 获取url中的参数
export function getQueryParams(url) {
  const paramArr = url.slice(url.indexOf('?') + 1).split('&')
  const params = {} as { [key: string]: string }
  paramArr.map((param) => {
    const [key, val] = param.split('=')
    params[key] = decodeURIComponent(val)
  })
  return params
}

export function copyText(text: string) {
  // 使用新api复制
  navigator.clipboard
    .writeText(text)
    .then(() => {
      message.success('复制成功')
    })
    .catch((err) => {
      message.error('复制失败')
    })
}

let key = 0
export function getKey() {
  if (key >= Number.MAX_VALUE) {
    key = 0
  }
  return String(key++)
}

export function openUrl(url: string, newWindonw = false) {
  if (newWindonw) {
    // 是否有父级窗口
    if (window.parent !== window) {
      window.parent.open(url)
    } else {
      window.open(url)
    }
  } else {
    // 是否有父级窗口
    if (window.parent !== window) {
      window.parent.location.href = url
    } else {
      window.location.href = url
    }
  }
}

// 这个只是用来统计
export function getUserId(){
  return window.localStorage.getItem('user-id')
}