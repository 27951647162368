export const SearchEnginesr = [
  {
    name: '百度',
    url: 'https://www.baidu.com/s?ie=utf-8&wd=',
    icon: 'https://files.codelife.cc/itab/search/baidu.svg'
  },
  {
    name: 'Google',
    url: 'https://www.google.com/search?q=',
    icon: 'https://files.codelife.cc/itab/search/google.svg'
  },
  {
    name: '必应',
    url: 'https://www.bing.com/search?q=',
    icon: 'https://files.codelife.cc/itab/search/bing.svg'
  },
  {
    name: 'Yandex',
    url: 'https://yandex.com/search/?text=',
    icon: 'https://files.codelife.cc/itab/search/yandex.svg'
  },
  {
    name: '360搜索',
    url: 'https://www.so.com/s?q=',
    icon: 'https://files.codelife.cc/itab/search/360.svg'
  },
  {
    name: 'F搜',
    url: 'https://fsoufsou.com/search?q=',
    icon: 'https://files.codelife.cc/itab/search/fsou.svg'
  },
  {
    name: '搜狗',
    url: 'https://www.sogou.com/sogou?query=',
    icon: 'https://files.codelife.cc/itab/search/sougou.svg'
  },
  {
    name: '秘塔AI',
    url: 'https://metaso.cn/?q=',
    icon: 'https://files.codelife.cc/itab/search/metaso.svg'
  },
  {
    name: 'GitHub',
    url: 'https://github.com/search?q=',
    icon: 'https://files.codelife.cc/itab/search/github.svg'
  },
  {
    name: '哔哩哔哩',
    url: 'https://search.bilibili.com/all?keyword=',
    icon: 'https://files.codelife.cc/itab/search/bilibili.svg'
  },
  {
    name: '抖音',
    url: 'https://www.douyin.com/search/{text}?ug_source=lenovo_stream',
    icon: 'https://files.codelife.cc/itab/search/douyin.svg'
  },
  {
    name: '京东',
    url: 'https://search.jd.com/Search?keyword=',
    icon: 'https://files.codelife.cc/itab/search/jd.svg'
  },
  {
    name: '微博',
    url: 'https://s.weibo.com/weibo?q=',
    icon: 'https://files.codelife.cc/itab/search/weibo.svg'
  },
  {
    name: '淘宝',
    url: 'https://ai.taobao.com/search/index.htm?key=',
    icon: 'https://files.codelife.cc/itab/search/taobao.svg'
  },
  {
    name: '小红书', // &m_source=
    url: 'https://www.xiaohongshu.com/search_result/?keyword=',
    icon: 'https://files.codelife.cc/itab/search/xiaohongshu.svg'
  },
  {
    name: 'NAVER',
    url: 'https://search.naver.com/search.naver?&query=',
    icon: 'https://files.codelife.cc/itab/search/naver.svg'
  },
  {
    name: 'DuckDuckGo',
    url: 'https://duckduckgo.com/?q=',
    icon: 'https://files.codelife.cc/itab/search/duckduckgo.svg'
  },
  {
    name: '知乎',
    url: 'https://www.zhihu.com/search?type=content&q=',
    icon: 'https://files.codelife.cc/itab/search/zhihu.svg'
  },
  {
    name: 'StackOverflow',
    url: 'https://stackoverflow.com/nocaptcha?s=',
    icon: 'https://files.codelife.cc/itab/search/stackoverflow.svg'
  },
  {
    name: 'Yahoo',
    url: 'https://hk.search.yahoo.com/search?p=',
    icon: 'https://files.codelife.cc/itab/search/yahoo.svg'
  },
  {
    name: 'Google Scholar',
    url: 'https://scholar.google.com/scholar?q=',
    icon: 'https://files.codelife.cc/itab/search/googlescholar.svg'
  },
  {
    name: 'MDN',
    url: 'https://developer.mozilla.org/zh-CN/search?q=',
    icon: 'https://files.codelife.cc/itab/search/mdn.svg'
  },
  {
    name: '开发者搜索',
    url: 'https://kaifa.baidu.com/searchPage?wd=',
    icon: 'https://files.codelife.cc/itab/search/kaifabaidu.svg'
  },
  {
    name: '豆瓣',
    url: 'https://www.douban.com/search?q=',
    icon: 'https://files.codelife.cc/itab/search/douban.svg'
  },
  {
    name: '头条',
    url: 'https://so.toutiao.com/search?dvpf=pc&keyword=',
    icon: 'https://files.codelife.cc/itab/search/toutiao.svg'
  }
]

export function getDefaultList() {
  return [
    {
      name: '首页',
      id: 'page-C7a28QiBqPDQFqJ4PxSwi',
      son: [
        {
          id: 'bookmark-zSyMwhE_EpZgOnsQu0ero',
          name: '在线工具',
          url: 'https://tool.lu/'
        },
        {
          id: 'bookmark-j-vvPNDFGZeXnLz-EiVpe',
          name: '文心一言',
          url: 'https://yiyan.baidu.com/'
        },
        {
          id: 'bookmark-vLsevzzM2WaHXCas9zfEc',
          name: '通义千问',
          url: 'https://tongyi.aliyun.com/'
        },
        {
          id: 'bookmark-zKSPUnUBpXBK5xg_OAO-N',
          name: 'ChatGPT',
          url: 'https://chat.openai.com/'
        },
        {
          id: 'bookmark-9B9eoLaLRYZ_89jxZKGM8',
          name: '腾讯混元',
          url: 'https://hunyuan.tencent.com/'
        },
        {
          id: 'bookmark-7FJsVqq2lbEwOf-2gqTdh',
          name: '百川大模型',
          url: 'https://www.baichuan-ai.com/home'
        },
        {
          id: 'bookmark-ht-76z3oJKCAH7_wce8cA',
          name: '盘古大模型',
          url: 'https://pangu.huaweicloud.com/'
        },
        { id: 'bookmark-7kA-Fap9xI72nt-d7Rjmg', name: '豆包', url: 'https://www.doubao.com/' },
        {
          id: 'bookmark-7LRgr0N3hSm8c0s6YPSaW',
          name: '讯飞星火',
          url: 'https://xinghuo.xfyun.cn/'
        },
        {
          id: 'bookmark-OG4eJeAb7S_NqdJIVIy1V',
          name: '通义听悟',
          url: 'https://tingwu.aliyun.com/?utm_source=ai-bot.cn'
        },
        {
          id: 'bookmark-JUZDRYAtutUwhRe3KFN3o',
          name: '秘塔写作猫',
          url: 'https://xiezuocat.com/'
        }
      ]
    },
    {
      name: '一些东西',
      id: 'page-hgbfLchcouaZrim2H3-lh',
      son: [
        {
          id: 'folder-fhrLuS8G6W5IxQPczTz7S',
          name: 'UI库',
          son: [
            {
              id: 'bookmark-RU4M_fEkfTZa5dV-_gzex',
              name: 'Element UI',
              url: 'https://element.eleme.cn/'
            },
            {
              id: 'bookmark--8s_akNV9_h5KwXMPTJUi',
              name: 'Ant Design Vue',
              url: 'https://www.antdv.com/docs/vue/introduce-cn/'
            },
            {
              id: 'bookmark-AdszJ1v4UFCjsLxX9ArbG',
              name: 'NaiveUi',
              url: 'https://www.naiveui.com/'
            },
            {
              id: 'bookmark-rfG4AI_poG-GF-sZxJkCk',
              name: 'Element Plus',
              url: 'https://element-plus.org/'
            },
            {
              id: 'bookmark-SzObbpjuFVSN1HhQByClk',
              name: 'Ant Design',
              url: 'https://ant.design/'
            },
            {
              id: 'bookmark-muVzLMZ5HxJh421EVPm3S',
              name: 'Arco Design',
              url: 'https://arco.design/'
            },
            {
              id: 'bookmark-2lIOwvx5sBcjjKWL_ybOk',
              name: 'Bootstrap',
              url: 'https://www.bootcss.com/'
            }
          ]
        },
        {
          id: 'folder-i4P65ZmLR5M4Old-rCFi4',
          name: '前端',
          son: [
            {
              id: 'bookmark-dd4ymEis7uus4p-EQpQMO',
              name: 'VUE',
              url: 'https://cn.vuejs.org/'
            },
            {
              id: 'bookmark-2g3NvNnR2PvNFKXKNMvvL',
              name: 'TypeScript',
              url: 'https://www.typescriptlang.org/'
            },
            { id: 'bookmark-4xCux0DmAwTyZZa1RTOf1', name: 'Vite', url: 'https://vitejs.dev/' },
            {
              id: 'bookmark-7_8xBApnEUBKU7Zyn30JB',
              name: 'React',
              url: 'https://zh-hans.reactjs.org/'
            },
            {
              id: 'bookmark-wXttl9S6YrnVIMevp9v_y',
              name: 'uni-app',
              url: 'https://uniapp.dcloud.io/'
            },
            {
              id: 'bookmark-gzYOOxIR5DsGtNSwD2RHM',
              name: 'Electron',
              url: 'https://www.electronjs.org/'
            },
            {
              id: 'bookmark-4-WiACZXrYbDx5h2nXvsX',
              name: 'W3School',
              url: 'https://www.w3school.com.cn/'
            },
            {
              id: 'bookmark-b7bIG61IrxSlU3bSMofor',
              name: 'Nuxt',
              url: 'https://nuxt.com.cn/docs/getting-started/installation'
            }
          ]
        },
        {
          id: 'folder-_mC6yCj5PaRwPXz9P2ybG',
          name: 'Echarts示例',
          son: [
            { id: 'bookmark-rqaITKPBQaOmq5cMvWeD2', name: 'ppchart', url: 'http://ppchart.com/' },
            {
              id: 'bookmark-oR6aXmmCVJXXnzOOmf6PE',
              name: 'makeapie',
              url: 'https://www.makeapie.cn/echarts'
            },
            {
              id: 'bookmark-lyAh9kOIovaDCr_T8qN7d',
              name: 'zhangmuchen.top',
              url: 'http://echarts.zhangmuchen.top/#/index'
            },
            {
              id: 'bookmark-Xcpqhii0RokQoGoNMyRUf',
              name: 'majh.top',
              url: 'http://chart.majh.top/'
            },
            {
              id: 'bookmark-ygIsMMYOtGMoQ3TKgIQcL',
              name: 'isqqw.com',
              url: 'https://www.isqqw.com/'
            }
          ]
        },
        { id: 'bookmark-bHmHKobqW3kaGeWKBgHr6', name: '图片转ICO', url: 'https://www.bitbug.net' }
      ]
    },
    {
      name: '资源',
      id: 'page-8dldjOXHEcKMmIWW0V5Tk',
      son: [
        {
          id: 'bookmark-qu57O2yj8HQec9mPMHP3m',
          name: 'MSDN系统库',
          url: 'https://www.xitongku.com/'
        },
        {
          id: 'bookmark-_gO5x3CNaa4htzme1a5q0',
          name: '浏览器插件下载',
          url: 'https://extfans.com/'
        }
      ]
    }
  ]
}
