import SDK from 'casdoor-js-sdk'
import type { SdkConfig } from 'casdoor-js-sdk/lib/cjs/sdk'
const serverUrl = 'https://auth.yyznm.cn'
const sdkConfig: SdkConfig = {
  serverUrl: serverUrl,
  clientId: '60601f42ebfbe292e6b6',
  organizationName: 'user',
  appName: 'start-page',
  redirectPath: '/',
  storage: localStorage
}
const Auth = new SDK(sdkConfig)
export default Auth

export function loginForWindow() {
  const width = 500
  const height = 600
  const left = window.screen.width / 2 - width / 2
  const top = window.screen.height / 2 - height / 2
  const url = new URL(Auth.getSigninUrl())
  url.searchParams.set('redirect_uri', serverUrl + '/files/login-open-call.html')
  const popupWindow = window.open(
    url.href + '&popup=1',
    'login',
    `width=${width},height=${height},top=${top},left=${left}`
  )
  const handleMessage = (event) => {
    console.log(event)
    popupWindow?.close()
    if (event.origin !== serverUrl) {
      return
    }
    if (event.data.type === 'loginSuccess') {
      sessionStorage.setItem('autn-code', event.data.code)
      window.location.reload() // 直接刷新，触发认证
    }
  }
  window.addEventListener('message', handleMessage)
}
