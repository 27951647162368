<template>
  <n-config-provider :theme="theme" :theme-overrides="themeOverrides" :locale="zhCN" :date-locale="dateZhCN">
    <n-modal-provider>
      <n-message-provider>
        <n-notification-provider>
          <n-dialog-provider>
            <IndexView />
          </n-dialog-provider>
        </n-notification-provider>
      </n-message-provider>
    </n-modal-provider>
  </n-config-provider>
</template>
<script setup lang="ts">
import IndexView from './views/index.vue'
import type { GlobalThemeOverrides } from 'naive-ui'
import { zhCN, dateZhCN, darkTheme, lightTheme } from 'naive-ui'
import { useThemeStoreWithOut } from './stores/theme';
const themeStore = useThemeStoreWithOut()
const theme = computed(() => {
  return themeStore.colorStyle == 'dark' ? darkTheme : lightTheme;
})
const themeOverrides: GlobalThemeOverrides = {
  common: {

  },
  Drawer: {
    borderRadius: "25px",
    headerBorderBottom: "1px solid var(--color-border)"
  },
  Form: {
    labelFontSizeLeftMedium: "18px",
    labelFontSizeTopMedium: "18px",
    labelFontWeight: "bold",
    labelTextColor: "var(--color-text)",
    lineHeight: '1'
  },
  Menu: {
    itemColorHover: "var(--item-hover)",
    dividerColor: "var(--color-border)",
  },
  Input: {
    color: "var(--item-bg)",
    colorFocus: "var(--item-bg)",
    border: "1px solid var(--color-border)"
  },
  Select: {
    peers: {
      InternalSelectMenu: {
        // color: "var(--item-bg)",
      },
      InternalSelection: {
        color: "var(--item-bg)",
        colorActive: "var(--item-bg-hover)"
      }
    },
  },
  Empty: {
    textColor: "var(--color-text)",
    iconColor: "var(--color-text)"
  },
  Tabs: {
    tabBorderColor: "var(--color-border)",
    tabFontWeight: "bold",
    tabFontWeightActive: "bold",
    tabFontSizeMedium: "17px"
  }
}
</script>
<style scoped></style>
