// 模块
import { defineStore } from 'pinia'
import { store } from '../main'
import { getUserId, message, toFullUrlForBaseUrl, useMethodsPrivate } from '@/utils'
import { io } from 'socket.io-client'
import { useEnginesrStoreWithOut } from './enginesr'
import { useWallpaperStoreWithOut } from './wallpaper'
import { useSiteStoreWithOut } from './site'
import { useBasicStoreWithOut } from './basic'
import { getToken } from '@/utils/token'
import { useThemeStoreWithOut } from './theme'
import { useAuthStoreWithOut } from './auth'
import { onEvent } from '@/utils/TJ'
export const useDataStore = defineStore('sync', {
  // 开启持久化
  persist: {
    paths: ['wallpaper', 'site', 'enginesr', 'basic']
  },
  state: () => ({
    wallpaper: true,
    site: true,
    enginesr: true,
    basic: true,
    theme: true,
    socket: null as any,
    socketId: ''
  }),
  getters: {},
  actions: {
    initSocket() {
      const { openSelectDataVersion } = useMethodsPrivate()
      this.socket = io(toFullUrlForBaseUrl('/'), {
        path: '/api/socket'
      })
      // client-side
      this.socket.on('connect', () => {
        // @ts-ignore
        window._SocketId = this.socket?.id
        this.socket?.emit('join', { Authorization: 'Bearer ' + getToken() }, (e: any) => {
          if (e.code == 401) {
            message.warning('登录过期，请重新登录')
            onEvent('登录过期', { 类型: 'SocketIo认证' })
            return
          }
          if (e.code === 200) {
            this.socket.on('data-update', (data) => {
              console.log(data)
              //接收服务器端的播放列表
              if (data.key === 'site' && this.site) {
                const site = useSiteStoreWithOut()
                site.stopWatchList()
                // 本来就是线上数据，直接更新
                if (site.dataStatu == 'online') {
                  site.onlineDataList({
                    list: JSON.parse(data.json),
                    update: data.update
                  })
                  message.success('收到来自其他设备的数据同步')
                } else {
                  // 可能会数据冲突，进一步处理
                  message.warning(
                    '收到来自其他设备的数据同步，但您本地已经修改过数据，请选择要使用的数据版本！'
                  )
                  openSelectDataVersion().then((comp) => {
                    comp.upData({
                      time: data.update, // 远程的时间
                      list: JSON.parse(data.json) // 远程的数据
                    })
                  })
                }
                site.startWatchList()
              } else if (data.key === 'wallpaper' && this.wallpaper) {
                const wallpaper = useWallpaperStoreWithOut()
                wallpaper.stopWatchWallpaper()
                wallpaper.$patch({ ...JSON.parse(data.json) })
                wallpaper.startWatchWallpaper()
                message.success('收到来自其他设备的壁纸设置')
              } else if (data.key === 'enginesr' && this.enginesr) {
                const enginesr = useEnginesrStoreWithOut()
                enginesr.stopWatchEnginesr()
                enginesr.$patch({ enginesrAll: JSON.parse(data.json), enginesrIndex: 0 })
                enginesr.startWatchEnginesr()
                message.success('收到来自其他设备的搜索引擎设置')
              } else if (data.key === 'basic' && this.basic) {
                const basic = useBasicStoreWithOut()
                basic.stopWatchBasic()
                basic.$patch({ ...JSON.parse(data.json) })
                basic.startWatchBasic()
                message.success('收到来自其他设备的基础设置')
              } else if (data.key === 'theme' && this.theme) {
                const theme = useThemeStoreWithOut()
                theme.stopWatchTheme()
                theme.$patch({ ...JSON.parse(data.json) })
                theme.startWatchTheme()
                message.success('收到来自其他设备的主题设置')
              }

              onEvent('同步下行', {
                同步内容: data.key,
                用户: getUserId()
              })
            })
          }
        })
      })
    }
  }
})
export function useSyncStoreWithOut() {
  return useDataStore(store)
}
