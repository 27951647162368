import { getUserId } from '@/utils'
import { onEvent } from '@/utils/TJ'
import http from '@/utils/http'

export function ApiUpdataList(data): Promise<any> {
  onEvent('同步上行', {
    同步内容: 'site',
    长度: parseInt(JSON.stringify(data).length / 1000 + '') * 1000,
    用户: getUserId()
  })
  return http.post('/api/sync/update-list', data)
}
export function ApiGetList(data): Promise<any> {
  return http.get('/api/sync/get-list', {
    params: data
  })
}
export function ApiUpdataEnginesr(data): Promise<any> {
  onEvent('同步上行', {
    同步内容: 'enginesr',
    长度: parseInt(JSON.stringify(data).length / 1000 + '') * 1000,
    用户: getUserId()
  })
  return http.post('/api/sync/update-enginesr', data)
}
export function ApiGetEnginesr(): Promise<any> {
  return http.get('/api/sync/get-enginesr')
}
export function ApiUpdataWallpaper(data): Promise<any> {
  onEvent('同步上行', {
    同步内容: 'wallpaper',
    长度: parseInt(JSON.stringify(data).length / 1000 + '') * 1000,
    用户: getUserId()
  })
  return http.post('/api/sync/update-wallpaper', data)
}
export function ApiGetWallpaper(): Promise<any> {
  return http.get('/api/sync/get-wallpaper')
}

export function ApiUpdataBasic(data): Promise<any> {
  onEvent('同步上行', {
    同步内容: 'basic',
    长度: parseInt(JSON.stringify(data).length / 1000 + '') * 1000,
    用户: getUserId()
  })
  return http.post('/api/sync/update-basic', data)
}
export function ApiGetBasic(): Promise<any> {
  return http.get('/api/sync/get-basic')
}

export function ApiUpdataTheme(data): Promise<any> {
  onEvent('同步上行', {
    同步内容: 'theme',
    长度: parseInt(JSON.stringify(data).length / 1000 + '') * 1000,
    用户: getUserId()
  })
  return http.post('/api/sync/update-theme', data)
}
export function ApiGetTheme(): Promise<any> {
  return http.get('/api/sync/get-theme')
}
