// 模块
import { defineStore } from 'pinia'
import { store } from '../main'
import { message } from '@/utils'
import { ApiUpdataEnginesr, ApiGetBasic, ApiUpdataBasic } from '@/api/sync'
import type { WatchStopHandle } from 'vue'
import { useSyncStoreWithOut } from './sync'
let basicWatch: WatchStopHandle
export const useDataStore = defineStore('basic', {
  // 开启持久化
  persist: {
    paths: ['searchNewTab', 'starNewTab']
  },
  state: () => ({
    searchNewTab: false,
    starNewTab: false
  }),
  getters: {},
  actions: {
    initSync() {
      const sync = useSyncStoreWithOut()
      ApiGetBasic()
        .then((res) => {
          if (res.type === 'ok' && res.json && sync.basic) {
            this.$patch({ ...JSON.parse(res.json) })
          }
          // 云端没有数据,推送更新
          if (res.type === 'empty' && sync.basic) {
            this.upData()
          }
          this.startWatchBasic()
        })
        .catch(() => {
          message.warning('获取云端基础设置失败，刷新页面重试')
        })
      return this
    },
    stopWatchBasic() {
      basicWatch && basicWatch()
    },
    startWatchBasic() {
      const sync = useSyncStoreWithOut()
      this.stopWatchBasic()
      basicWatch = watch(
        [() => sync.basic, this.$state],
        ([sync, list]) => {
          if (!sync) return
          this.upData()
        },
        { deep: true }
      )
    },
    upData() {
      ApiUpdataBasic({ json: JSON.stringify(this.$state) }).catch(() => {
        message.warning('基础设置同步失败')
      })
    }
  }
})
export function useBasicStoreWithOut() {
  return useDataStore(store)
}
