// 模块
import { defineStore } from 'pinia'
import { store } from '../main'
import { message, openUrl, useMethodsPrivate } from '@/utils'
import { ApiGetList, ApiUpdataList } from '@/api/sync'
import type { WatchStopHandle } from 'vue'
import { useSyncStoreWithOut } from './sync'
import { useBasicStoreWithOut } from './basic'
import { getDefaultList } from '@/utils/data'
import type { SiteType } from '@/types/public'
const { openSelectDataVersion, openSeeFolder } = useMethodsPrivate()


let siteWatch: WatchStopHandle
let siteLocalWatch: WatchStopHandle
export const useDataStore = defineStore('site', {
  // 开启持久化
  persist: {
    paths: ['list', 'onlineUpdate', 'update', 'dataStatu']
  },
  state: () => ({
    list: getDefaultList() as SiteType[],
    update: 0, // 远程的数据版本
    onlineUpdate: 0,
    dataStatu: 'default' as 'default' | 'local' | 'online'
  }),
  getters: {
    getList(state) {
      return state.list
    }
  },
  actions: {
    init() {
      this.startLocalWatch()
      return this
    },
    // 本地数据改变监听
    startLocalWatch() {
      siteLocalWatch && siteLocalWatch()
      siteLocalWatch = watch(
        () => this.list,
        () => {
          console.log('list更新', Date.now())
          this.dataStatu = 'local'
          this.update = Date.now()
        },
        {
          deep: true
        }
      )
    },
    // 本地数据改变监听停止
    stopLocalWatch() {
      siteLocalWatch && siteLocalWatch()
    },
    onlineDataList({ list, update }: { list?: SiteType[]; update?: number }) {
      this.stopWatchList()
      this.stopLocalWatch()
      // 非用户本地操作的更新，使用这个方法更新数据
      if (list) {
        this.$patch({ list: list })
      }
      if (update) {
        this.$patch({ update: update, onlineUpdate: update })
      }
      this.$patch({ dataStatu: 'online' })
      this.startWatchList()
      this.startLocalWatch()
    },
    initSync() {
      if (this.dataStatu == 'online') {
        // 查询是否有更新，可以直接更新
        this.getListBuOnline().then(this.startWatchList)
      } else {
        // 是本地的数据，请求一下更新
        this.updateList().then(this.startWatchList)
      }
      return this
    },
    startWatchList() {
      const sync = useSyncStoreWithOut()
      // 节流同步设置
      let timer: any
      siteWatch && siteWatch()
      siteWatch = watch(
        [() => sync.site, () => this.list],
        ([sync], [oldSync]) => {
          if (sync === oldSync) {
            // 非同步设置的更新
            this.dataStatu = 'local'
            this.update = Date.now()
          }
          clearTimeout(timer)
          if (!sync) return
          // console.log('3秒后更新数据')
          timer = setTimeout(this.updateList, oldSync ? 3000 : 0)
        },
        {
          deep: true
        }
      )
    },
    stopWatchList() {
      siteWatch && siteWatch()
    },
    handleData(res: { type: string; update: number; json: string }) {
      const sync = useSyncStoreWithOut()
      // 云端没有数据，推送一版
      if (res.type === 'empty') {
        sync.site && this.updateList(true)
        return
      } else if (res.type === 'diff') {
        // 数据不一致
        // 本地数据已经被改动
        if (this.dataStatu === 'local') {
          message.warning('您的数据与云端的数据版本不一致，请选择要使用的数据版本！')
          openSelectDataVersion().then((comp) => {
            comp.upData({
              time: res.update, // 远程的时间
              list: JSON.parse(res.json) // 远程的数据
            })
          })
          return // 结束掉，不要更新状态
        }
        // 剩下两种情况
        // 本来也是线上的数据或者是默认的数据，直接更新
        message.success('已同步云端数据')
        this.onlineDataList({
          list: JSON.parse(res.json)
        })
      } else if (res.type == 'same') {
        // console.log('数据与云端的数据版本一致，无需更新！')
      }
      this.$patch({
        update: res.update,
        onlineUpdate: res.update, // 记录线上数据的时间
        dataStatu: 'online' // 标记为线上数据
      })
    },
    getListBuOnline() {
      return ApiGetList({
        update: this.update
      }).then(this.handleData)
    },
    updateList(force?: boolean) {
      return ApiUpdataList({
        json: JSON.stringify(this.list),
        update: this.onlineUpdate, // 使用线上数据的时间，如果一样可以直接更新
        force: !!force
      }).then(this.handleData)
    },
    goSiteById(id: string, isNewTab?: boolean) {
      const basic = useBasicStoreWithOut()
      // 递归list
      function dg(list?: SiteType[]) {
        if (!list) return false
        for (let i = 0; i < list.length; i++) {
          if (list[i].id == id) {
            // 是否是一个文件夹
            if (list[i].son !== void 0) {
              openSeeFolder(id)
              return true
            }
            const url = list[i].url
            if (!url) {
              message.warning('该网站没有配置链接')
            } else {
              openUrl(url, isNewTab === void 0 ? basic.starNewTab : isNewTab)
            }
            return true
          }
          if (dg(list[i]?.son)) return true
        }
      }
      dg(this.list)
    },
    // 递归查找节点
    getNodeById(id: string, list?: SiteType[]): SiteType | undefined {
      list = list || this.list
      for (let i = 0; i < list.length; i++) {
        const node = list[i]
        if (node.id === id) return node
        if (node.son && node.son.length > 0) {
          const node2 = this.getNodeById(id, node.son)
          if (node2) return node2
        }
      }
    },
    // 递归查找节点的层级
    getNodeLayerById(
      id: string,
      list?: SiteType[],
      layer: SiteType[] = []
    ): SiteType[] | undefined {
      list = list || this.list
      for (let i = 0; i < list.length; i++) {
        const node = list[i]
        if (node.id === id) {
          layer.unshift(node)
          return layer
        }
        if (node.son && node.son.length > 0) {
          const node2 = this.getNodeLayerById(id, node.son, layer)
          if (node2) {
            layer.unshift(node)
            return layer
          }
        }
      }
    }
  }
})
export function useSiteStoreWithOut() {
  return useDataStore(store)
}
