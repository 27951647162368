import http from '@/utils/http'

export function ApiGetUserInfo(): Promise<any> {
  return http.get('/api/auth/getUserInfo')
}

export function ApiSignin(code): Promise<any> {
  return http.get('/api/auth/signin', {
    params: {
      code
    }
  })
}

let promise
export function ApiRefreshToken() {
  if (promise) {
    return promise
  }
  promise = http
    .get('/api/auth/refreshToken', {
      /// @ts-ignore
      __isRefreshToken: true
    })
    .then(() => {
      return true
    })
    .catch(() => {
      return false
    })
    .finally(() => {
      promise = null
    })
  return promise
}

// 是否是刷新token的请求config
export function isRefreshToken(config) {
  return !!config.__isRefreshToken
}
