// 模块
import { defineStore } from 'pinia'
import { store } from '../main'
import sdk from 'casdoor-js-sdk'
import Auth from '@/utils/auth'
import { ApiGetUserInfo, ApiSignin } from '@/api/auth'
import { getQueryParams, message } from '@/utils'
import { getToken } from '@/utils/token'
export const useDataStore = defineStore('auth', {
  persist: {
    paths: []
  },
  state: () => ({
    isLogin: false,
    user: {
      avatar: '',
      displayName: '',
      email: '',
      id: ''
    }
  }),
  getters: {},
  actions: {
    init() {
      // 获取url上的code
      const code = getQueryParams(window.location.href).code || sessionStorage.getItem('autn-code')
      if (code) {
        window.history.replaceState({}, '', window.location.href.split('?')[0])
        sessionStorage.removeItem('autn-code')
        return ApiSignin(code)
          .then((res: any) => {
            this.user = res
            this.isLogin = true
            localStorage.setItem('user-id', res.id)
            message.success('登录成功 🚀')
          })
          .catch((e) => {
            message.error('登录失败: ' + e.msg)
            this.isLogin = false
            localStorage.removeItem('user-id')
            return Promise.resolve()
          })
          .finally(() => {
            // 清楚url上的code,不刷新页面
            // window.history.replaceState({}, '', window.location.href.split('?')[0])
            // sessionStorage.removeItem('autn-code')
          })
      } else {
        if (getToken()) {
          return ApiGetUserInfo().then((res: any) => {
            this.isLogin = true
            this.user = res
            localStorage.setItem('user-id', res.id)
          })
        }
        return Promise.reject()
      }
    },
    cleadToken() {
      localStorage.removeItem('token')
      localStorage.removeItem('refresh-token')
      localStorage.removeItem('expiration-time')
    }
  }
})
export function useAuthStoreWithOut() {
  return useDataStore(store)
}
