<template>
    <div class="main">
        <ThemeView class="ThemeView" />
        <Setup v-model:show="openSetup" />
        <RightMenu ref="rightMenu" />
        <component :ref="(e) => compRefCall(item.id, e)" v-for="item in components" :key="item.id" :is="item.comp"
            v-model:show="shows[item.id]" />
        <n-spin size="medium" :delay="200" v-if="loading"
            style="position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);" />
    </div>
</template>
<script setup lang="ts">
import ThemeView from './theme/index.vue'
import { provide, defineAsyncComponent } from 'vue'
import { useSyncStoreWithOut } from '@/stores/sync';
import { useSiteStoreWithOut } from '@/stores/site';
import { useWallpaperStoreWithOut } from '@/stores/wallpaper';
import { useEnginesrStoreWithOut } from '@/stores/enginesr';
import { useBasicStoreWithOut } from '@/stores/basic';
import { useThemeStoreWithOut } from '@/stores/theme';
import { useAuthStoreWithOut } from '@/stores/auth';
import { initUtils } from '@/utils'
import { init } from './index'
const Setup = defineAsyncComponent(() => import('./setup/Setup.vue'))
const RightMenu = defineAsyncComponent(() => import('@/components/RightMenu/index.vue'))
const setupIf = ref(false)
const site = useSiteStoreWithOut().init()
const wallpaper = useWallpaperStoreWithOut()
const enginesr = useEnginesrStoreWithOut()
const auth = useAuthStoreWithOut()
const theme = useThemeStoreWithOut().init()

// 初始化一些方法
const { compRefCall, shows, components, loading, openSetup, rightMenu } = init()

// 初始化工具方法里面的一些东西
initUtils()

// 先完成登陆
auth.init().then(() => {
    if (auth.isLogin) {
        useSyncStoreWithOut().initSocket()
        site.initSync()
        wallpaper.initSync()
        enginesr.initSync()
        useBasicStoreWithOut().initSync()
        theme.initSync()
    }
}).catch(() => {
    wallpaper.init() // 没有登陆使用本地数据主动刷新一下壁纸
    console.log('未登陆');
})

watch(openSetup, () => {
    setupIf.value = true
})
provide('data:theme', computed(() => theme.$state))
provide('data:site', computed(() => site.list))
provide('data:wallpaper', computed(() => wallpaper.$state))
provide('data:enginesr', computed(() => enginesr.enginesr))
provide('data:currentEnginesr', computed(() => enginesr.currentEnginesr))
</script>
<style scoped>
.main {
    width: 100%;
    height: 100vh;
    position: relative;
}

.ThemeView {
    width: 100%;
    height: 100vh;
    position: relative;
}
</style>