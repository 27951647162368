// 模块
import { defineStore } from 'pinia'
import { store } from '../main'
import { message } from '@/utils'
import { ApiGetWallpaper, ApiUpdataWallpaper } from '@/api/sync'
import type { WatchStopHandle } from 'vue'
import { useSyncStoreWithOut } from './sync'
import { ApiBingYing, ApiWallpaperServer } from '@/api/wallpaper'
import { WallpaperType } from '@/types/public'
let wallpaperWatch: WatchStopHandle
export const useDataStore = defineStore('wallpaper', {
  // 开启持久化
  persist: {
    paths: ['other', 'type', 'url', 'css', 'blur', 'mask', 'date']
  },
  state: () => ({
    other: {} as any,
    type: 'bingying',
    url: '',
    css: '',
    blur: 0,
    mask: 30,
    date: '每天0点', // 更新间隔
    update: ''
  } as WallpaperType),
  getters: {},
  actions: {
    init() {
      // 初始化壁纸
      if (this.date !== '关闭' || this.type == 'bingying') {
        // 判断是否需要更新
        const now = new Date()
        const nowDate =
          {
            每天0点: `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`,
            每小时: `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()} ${now.getHours()}`,
            每次打开: String(now.getTime())
          }[this.type == 'bingying' ? '每天0点' : this.date] || '-'
        if (nowDate != this.update) {
          // 需要更新
          this.refresh()
          this.update = nowDate
        }
      }
      return this
    },
    initSync() {
      const sync = useSyncStoreWithOut()
      // 壁纸
      ApiGetWallpaper()
        .then((res) => {
          if (res.type === 'ok' && res.json && sync.wallpaper) {
            this.$patch({ ...JSON.parse(res.json) })
          }
          // 云端没有数据,推送更新
          if (res.type === 'empty' && sync.wallpaper) {
            this.upData()
          }
          this.init()
          this.startWatchWallpaper()
        })
        .catch(() => {
          message.warning('获取云端壁纸失败，刷新页面重试')
        })
      return this
    },
    refresh() {
      if (this.type == 'bingying') {
        ApiBingYing().then((res: any) => {
          this.url = res.url
          this.css = `url(${res.url})`
        })
      } else if (this.type == 'dubscribed') {
        let data = {}
        if (this.other.type == 'url') {
          // 自定义
          data = {
            url: this.other.serviceUrl,
            type: '301'
          }
        } else {
          // 自带的
          data = {
            mode: this.other.type
          }
        }
        ApiWallpaperServer(data).then((res: any) => {
          this.url = res.url
          this.css = `url(${res.url})`
        })
      }
    },
    stopWatchWallpaper() {
      wallpaperWatch && wallpaperWatch()
    },
    startWatchWallpaper() {
      let timer2: any
      this.stopWatchWallpaper()
      const sync = useSyncStoreWithOut()
      wallpaperWatch = watch(
        [() => sync.wallpaper, () => this.$state],
        ([sync, data], [oldSync]) => {
          if (!sync) return
          // 1秒钟的节流
          clearTimeout(timer2)
          timer2 = setTimeout(
            () => {
              this.upData()
            },
            oldSync ? 1000 : 0
          )
        },
        { deep: true }
      )
    },
    upData() {
      ApiUpdataWallpaper({ json: JSON.stringify(this.$state) }).catch(() => {
        message.warning('壁纸同步失败')
      })
    }
  }
})
export function useWallpaperStoreWithOut() {
  return useDataStore(store)
}
