import './assets/main.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import * as Vue from 'vue'
import * as pinia from 'pinia'
import * as naiveUi from 'naive-ui'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import * as Sentry from '@sentry/vue'

// @ts-ignore
window.Vue = Vue
// @ts-ignore
window.pinia = pinia
// @ts-ignore
window.NaiveUi = naiveUi

const app = createApp(App)
export const store = createPinia()
store.use(
  createPersistedState({
    key: (id) => `_z_${id}`
  })
)
app.use(store)
Sentry.init({
  app,
  dsn: 'https://5b89ae468841d2cbbcce251baea9f647@o4507507235356672.ingest.de.sentry.io/4507507238436944',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.mount('#app')
