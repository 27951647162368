// 模块
import { defineStore } from 'pinia'
import { store } from '../main'
import { message } from '@/utils'
import { ApiGetTheme, ApiUpdataTheme } from '@/api/sync'
import type { WatchStopHandle } from 'vue'
import { useSyncStoreWithOut } from './sync'

export type ThemeInfo = {
  id: string,
  name: string,
  type: 'lib' | 'iframe',
  url: string,
  img: string,
  imgHd: string[],
  description: string
}

export type ThemeType = {
  colorStyle: 'dark' | 'light',
  iconRadius: number,
  info: ThemeInfo & {devUrl?:string, isDev?: boolean},
  setup: any
}

let themeWatch: WatchStopHandle
export const useDataStore = defineStore('theme', {
  // 开启持久化
  persist: {
    paths: ['info', 'setup', 'colorStyle', 'iconRadius']
  },
  state: () => ({
    colorStyle: 'light' as 'dark' | 'light',
    iconRadius: 10,
    info: {
      id: '222',
      name: '官方1',
      type: 'lib',
      url: '/theme/002',
      img: "https://img.cdn.yyzhu.vip/2024/06/25/221958215.png",
      imgHd: ["https://img.cdn.yyzhu.vip/2024/06/25/221934739.png"],
      description: '这是默认的主题哦！',
      devUrl: ""
    },
    setup: {}
  } as ThemeType),
  getters: {},
  actions: {
    init() {
      // 设置夜间模式到html
      watch(
        () => this.colorStyle,
        (newValue) => {
          document.documentElement.setAttribute('theme', newValue)
        },
        {
          immediate: true
        }
      )
      return this
    },
    initSync() {
      const sync = useSyncStoreWithOut()
      ApiGetTheme()
        .then((res) => {
          // 还没有云端数据，不处理他
          if (res.type === 'ok' && res.json && sync.theme) {
            if(JSON.stringify(this.$state) !== res.json){
              this.$patch({ ...JSON.parse(res.json) })
            }
          }
          // 云端没有数据,推送更新
          if (res.type === 'empty' && sync.theme) {
            this.upData()
          }
          this.startWatchTheme()
        })
        .catch(() => {
          message.warning('获取云端搜索引擎失败，刷新页面重试')
        })
      return this
    },
    stopWatchTheme() {
      themeWatch && themeWatch()
    },
    startWatchTheme() {
      let timer2: any
      const sync = useSyncStoreWithOut()
      this.stopWatchTheme()
      themeWatch = watch(
        [() => sync.theme, this.$state],
        ([sync, data], [oldSync]) => {
          if (!sync) return
          // 1秒钟的节流
          clearTimeout(timer2)
          timer2 = setTimeout(
            () => {
              this.upData()
            },
            oldSync ? 1000 : 0
          )
        },
        { deep: true }
      )
    },
    upData() {
      ApiUpdataTheme({ json: JSON.stringify(this.$state) }).catch(() => {
        message.warning('主题样式同步失败')
      })
    },
    changeColorStyle(isDark?: boolean) {
      if (isDark === void 0) {
        this.colorStyle = this.colorStyle === 'light' ? 'dark' : 'light'
      } else {
        this.colorStyle = isDark ? 'dark' : 'light'
      }
    },

    refreshTheme() {}
  }
})
export function useThemeStoreWithOut() {
  return useDataStore(store)
}
